:root {
  --color-primary: #12131c;
  --color-secondary: #1e202f;
  --font-color: white;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Ensure padding and border are included in width/height */
}

html, body {
  height: 100%;
}

body {
  background-color: var(--color-primary);
}

div.navbar {
  background-color: var(--color-secondary);
  border: none;
  outline: none;
}

.scrape-rates-container {
  padding: 45px;
  background-color: #f9f9f9;
}

.notes-card {
  background-color: #e0f7fa; 
  border-radius: 8px; 
  padding: 20px; 
  margin: 20px 0; 
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 
}

.notes-card h2 {
  margin-bottom: 10px;
  font-size: 1.2em; /* Larger font size for headings */
}

.notes-card ul {
  list-style-type: disc; 
  margin-left: 20px; 
  padding: 10px; /* Add padding for better spacing */
  line-height: 1.5; /* Increase line height for better readability */
}

.notes-card a {
  color: #007BFF; 
  text-decoration: none; 
}

.notes-card a:hover {
  text-decoration: underline; 
}

.card-category {
  margin-bottom: 20px;
}

.card-container {
  display: flex;
  flex-wrap: wrap; 
  gap: 20px; 
  justify-content: space-around; 
}

.card3 {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px; /* Increase padding for content */
  width: calc(18% - 20px); 
  height: auto; 
  text-align: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex; 
  flex-direction: column; 
  justify-content: flex-start; 
  overflow: hidden; 
}

.card-content {
  flex-grow: 1; 
  display: flex;
  flex-direction: column; 
  justify-content: center; 
}

.card3 img {
  width: 80%;  
  max-height: 80px; 
  object-fit: contain;  
  border-radius: 5px; 
  margin: 0 auto 10px auto; 
  cursor: pointer; 
}

.card3 p {
  margin: 5px 0; 
  font-size: 0.9em; 
  text-overflow: ellipsis; 
  overflow: hidden; 
  white-space: nowrap; 
}

.note {
  font-size: 0.8em; 
  color: #777;
  margin-top: 5px; /* Space above note */
  line-height: 1.4; /* Increased line height for notes */
}

/* Responsive Adjustments */
@media (max-width: 1200px) {
  .card3 {
      width: calc(22% - 20px); 
  }
}

@media (max-width: 992px) {
  .card3 {
      width: calc(30% - 20px); 
  }
}

@media (max-width: 768px) {
  .card3 {
      width: calc(40% - 20px); 
  }
}

@media (max-width: 576px) {
  .card3 {
      width: calc(100% - 20px); 
  }

  .scrape-rates-container {
      padding: 20px; 
  }

  .notes-card {
      margin: 10px; 
  }

  .notes-card h2 {
      font-size: 1.2em; 
  }
  
  .note {
      font-size: 0.75em; 
  }
}
