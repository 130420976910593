.franchiseRegistration {
    max-width: 600px; /* Limit the form width */
    margin: 50px auto; /* Center the form on the page */
    padding: 20px; /* Add padding inside the form */
    background-color: #f8f9fa; /* Light background for the form */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  }
  
  .franchiseRegistration h2 {
    text-align: center; /* Center the heading */
    color: #333; /* Darker heading text */
    margin-bottom: 20px; /* Add space below the heading */
  }
  
  .form-group {
    margin-bottom: 15px; /* Space between form elements */
  }
  
  .form-group label {
    display: block; /* Label takes full width */
    margin-bottom: 5px; /* Space below the label */
    font-weight: bold; /* Bold label text */
    color: #333; /* Darker label text */
  }
  
  .form-group input {
    width: 100%; /* Full width input fields */
    padding: 10px; /* Space inside the input fields */
    border: 1px solid #ccc; /* Light grey border */
    border-radius: 5px; /* Slightly rounded corners */
    font-size: 16px; /* Increase input text size */
    box-sizing: border-box; /* Ensure padding is included in width */
    transition: border-color 0.3s ease; /* Smooth transition on focus */
  }
  
  .form-group input:focus {
    border-color: #28a745; /* Green border on focus */
    outline: none; /* Remove default blue outline */
  }
  
  button[type="submit"] {
    width: 100%; /* Full width button */
    padding: 12px; /* Add padding to button */
    background-color: #28a745; /* Green background */
    color: white; /* White text */
    border: none; /* Remove borders */
    border-radius: 5px; /* Rounded corners */
    font-size: 18px; /* Larger button text */
    cursor: pointer; /* Pointer on hover */
    transition: background-color 0.3s ease; /* Smooth transition on hover */
  }
  
  button[type="submit"]:hover {
    background-color: #218838; /* Darker green on hover */
  }
  
  button[type="submit"]:disabled {
    background-color: #ccc; /* Grey color when disabled */
    cursor: not-allowed; /* Not-allowed cursor when disabled */
  }
  
  @media (max-width: 768px) {
    .franchiseRegistration {
      padding: 15px; /* Reduce padding on smaller screens */
    }
  
    .form-group input {
      font-size: 14px; /* Smaller input text size */
    }
  
    button[type="submit"] {
      font-size: 16px; /* Slightly smaller button text */
    }
  }
  