/* Base styles (for desktop and large screens) */
.homeScreen {
  display: flex;
  flex-direction: row; /* Ensure horizontal alignment */
  width: 100%;
  height: auto; /* Adjust height for content */
  color: white;
  justify-content: space-between;
  margin-bottom: 40px;
}

.leftHome {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  padding: 20px;
}

.leftupper {
  margin-top: 80px;
  margin-right: 100px;
}

.leftupper h3 {
  margin: 0;
  font-size: 3rem;
  color: white;
}

.scrapify {
  background: linear-gradient(to right, #66bb6a, #388e3c);
  cursor: pointer;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.rightHome {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.leftupper h2 {
  margin: 10px 0;
  font-size: 2rem;
  color: white;
  font-weight: 300;
}

.download {
  font-style: italic;
  color: #555;
}

.downloadlink {
  font-style: italic;
  cursor: pointer;
}

.homeimg {
  margin-top: 20px;
  width: 70%;
  height: auto; /* Adjust height for responsive images */
  border-radius: 8px;
}

/* User Details Card */
.card {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(102, 187, 106, 0.5);
  padding: 15px;
  width: 400px;
  margin: 20px auto;
  text-align: left;
}

.card h2 {
  margin-bottom: 15px;
  font-size: 1.4rem;
  color: #333;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.form-group label {
  font-weight: bold;
  margin-bottom: 5px;
  color: #555;
}

.form-group input {
  width: 100%;
  height: 35px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  color: #333;
}

.next-button {
  background-color: #66bb6a;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 10px;
  width: 100%;
}

.next-button:hover {
  background-color: #529556;
}

/* Mobile Responsive */
@media (max-width: 600px) {
  .homeScreen {
    flex-direction: column; /* Stack the home sections vertically */
  }

  .leftupper h3 {
    font-size: 2rem; /* Smaller font size */
  }

  .leftupper h2 {
    font-size: 1.5rem; /* Smaller font size */
  }

  .leftupper {
    margin: 40px 0; /* Less margin on mobile */
  }

  .homeimg {
    width: 70%; /* Adjust image size */
  }

  .card {
    width: 90%; /* Card takes most of the screen width */
    padding: 10px; /* Adjusted padding for mobile */
  }

  .card h2 {
    font-size: 1.2rem; /* Smaller font size for mobile */
  }

  .form-group input {
    height: 30px; /* Smaller input height */
    padding: 6px; /* Adjusted padding */
  }

  .next-button {
    font-size: 0.9rem; /* Smaller font size for mobile */
    padding: 8px 15px; /* Smaller padding for mobile */
  }
}

.franchise-button {
  background-color: #28a745; /* Green background */
  color: white; /* White text */
  border: none; /* Remove borders */
  padding: 12px 20px; /* Add some padding */
  font-size: 16px; /* Increase font size */
  cursor: pointer; /* Pointer/hand icon on hover */
  margin-top: 20px; /* Add some margin on top */
  border-radius: 5px; /* Rounded corners */
  transition: background-color 0.3s ease; /* Smooth hover transition */
}

.franchise-button:hover {
  background-color: #218838; /* Darker green on hover */
}


/* Tablet Responsive */
@media (min-width: 601px) and (max-width: 1024px) {
  .card {
    width: 80%; /* Adjusted width for tablets */
  }

  .card h2 {
    font-size: 1.3rem; /* Adjusted font size for tablets */
  }

  .form-group input {
    height: 35px; /* Input height for tablets */
  }

  .next-button {
    font-size: 1rem; /* Button font size for tablets */
  }
  
  .homeimg {
    width: 90%; /* Adjust image size for mobile */
  }
}

/* Large screens (1024px and above) */
@media (min-width: 1025px) {
  .leftupper h3 {
    font-size: 3rem; /* Font size for large screens */
  }

  .leftupper h2 {
    font-size: 2rem; /* Font size for large screens */
  }

  .homeimg {
    width: 50%; /* Image size for large screens */
  }

  .card {
    width: 400px; /* Original width for large screens */
  }
}


