/* Global Styles */
.navbarBelt {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    color: white;
    box-sizing: border-box;
    padding: 0 20px; /* Added padding for spacing on smaller screens */
}

.leftNavBelt {
    display: flex;
    gap: 10px;
    padding: 10px;
}

.leftNavBeltLogo {
    width: 110px;
    height: 30px;
    text-decoration: none;
    align-items: center;
    color: white;
    display: flex;
    padding: 2px;
    cursor: pointer;
}

.leftNavBeltLogo:hover {
    border: 1px solid;
    border-radius: 3px;
}

.scrapifyLogo {
    height: 140%;
}

.scrapifyText {
    margin-left: 4px;
    font-weight: 700;
}

.mainNav {
    display: flex;
    gap: 25px;
    padding: 8px;
}

.home, .com {
    font-weight: 400;
    cursor: pointer;
}

.home:hover, .com:hover {
    border: 1px solid;
    box-shadow: 0px 2px 4px green;
}

.rightSideNavBarBelt {
    display: flex;
    padding: 8px;
    align-items: center;
}

.googleLogo {
    height: 100%;
    padding: 8px;
}

.imagegoogle {
    height: 130%;
    width: 180px;
    cursor: pointer;
}

.imagegoogle:hover {
    border: 1px solid;
    box-shadow: 0px 2px 4px green;
}

/* Responsive Styles */

/* Tablet and below */
@media (max-width: 768px) {
    .navbarBelt {
        flex-direction: column; /* Stack navigation vertically */
        height: auto;
        padding: 10px;
    }

    .leftNavBelt {
        justify-content: center;
        margin-left: 0;
        margin-bottom: 10px;
    }

    .mainNav {
        flex-direction: column; /* Stack navigation links vertically */
        align-items: center;
        gap: 15px;
    }

    .rightSideNavBarBelt {
        justify-content: center;
        margin-right: 0;
    }

    .imagegoogle {
        width: 150px; /* Reduce width for smaller screens */
        height: auto;
    }
}

/* Mobile devices */
@media (max-width: 480px) {
    .navbarBelt {
        flex-direction: column;
        padding: 10px;
    }

    .leftNavBeltLogo {
        width: 90px; /* Smaller logo for mobile */
        height: 25px;
    }

    .mainNav {
        gap: 10px; /* Reduce gap between nav items */
    }

    .home, .com {
        font-size: 14px; /* Smaller text for mobile */
    }

    .imagegoogle {
        width: 120px; /* Further reduce image size */
    }

    .scrapifyText {
        font-size: 0.9rem; /* Reduce logo text size */
    }
}

/* Desktop large screens */
@media (min-width: 1200px) {
    .navbarBelt {
        padding: 0 50px; /* More padding for large screens */
    }

    .leftNavBeltLogo {
        width: 130px; /* Larger logo for bigger screens */
        height: 40px;
    }

    .imagegoogle {
        width: 200px; /* Larger image on large screens */
    }
}
